import React, { useRef, useEffect, useState } from "react";
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup';
import { Company } from "types/dataResponse";
import { BASE_URL } from "utils/requests";
import Input from "components/Form/Input";
import { authHeader, handleResponse } from "helpers";
import Select from "components/Form/Select";

interface FormData {
    company: string
    name: string
}

const CreateProject = (props: any) => {
    const [status, setStatus] = useState(null);
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = async data => {
        try {
            const schema = Yup.object().shape({
                name: Yup.string().required("O nome é obrigatório"),
                company: Yup.string().required("Selecione uma empresa")
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            formRef.current?.setErrors({});

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(
                    {
                        company: {
                            id: data.company
                        },
                        name: data.name
                    }
                )
            }

            fetch(`${BASE_URL}/project`, requestOptions)
                .then(handleResponse)
                .then(response => {
                    formRef.current?.reset();
                    props.history.push("/configuration");
                })
                .catch(error => {
                    setStatus(error);
                });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                let errorMessages: { [key in string]: string };
                errorMessages = {};
                err.inner.forEach((error: Yup.ValidationError) => {
                    errorMessages[error.path!] = error.message;
                });
                formRef.current?.setErrors(errorMessages);
            }
        }
    }

    const [companies, setCompanies] = useState<Company[]>([]);

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`${BASE_URL}/company`, requestOptions)
            .then(handleResponse)
            .then(response => {
                setCompanies(response.data);
            });
    }, []);

    return (
        <div className="container">
            {status &&
                <div className={'alert alert-danger'}>{status}</div>
            }
            <h3 className="display-5 font-weight-light text-secondary">Cadastrar Projeto</h3>
            <p className="lead font-weight-light text-secondary">Preencha os dados abaixo:</p>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="jumbotron shadow rounded bg-white mt-5 pt-4">
                    <h5 className="pb-3">Dados</h5>
                    <Input label="Nome do Projeto" name="name" type="text" placeholder="Insira o nome do projeto" />
                    <Select label="Empresa" name="company">
                        {companies?.map(company => (<option key={company.id} value={company.id}>{company.name}</option>))}
                    </Select>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-5">
                    <button type="submit" className="btn btn-warning mx-4">Cadastrar</button>
                    <a href="/configuration" className="btn btn-outline-warning mx-4 text-reset">Cancelar</a>
                </div>
            </Form>
        </div>
    );
}

export default CreateProject;
