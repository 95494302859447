/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { DataPage } from "types/dataResponse";
import { BASE_URL } from "utils/requests";
import Pagination from "../Pagination";
import { PencilFill, TrashFill } from "react-bootstrap-icons";
import { authHeader, handleResponse } from "helpers";

const returnUserPermission = (permission: string) => {
  if (permission === "user") {
    return "Usuário";
  } else if (permission === "admin") {
    return "Administrador";
  } else if (permission === "manager") {
    return "Gestor";
  } else {
    return "";
  }
};

const DataTable = () => {
  const [status, setStatus] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [page, setPage] = useState<DataPage>({
    first: true,
    last: true,
    number: 0,
    totalElements: 0,
    totalPages: 0,
  });

  const loadUsers = (activePage: number) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${BASE_URL}/users?page=${activePage}&size=20&sort=name,asc`,
      requestOptions
    )
      .then(handleResponse)
      .then((response) => {
        setPage(response.data);
      });
  };

  const deleteUser = async (name: string, email: string, id: number) => {
    const result = window.confirm(
      `Você realmente deseja desabilitar o usuário ${name} com o email ${email}?`
    );
    if (result) {
      const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
      };

      fetch(`${BASE_URL}/users/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => loadUsers(0))
        .catch((error) => {
          setStatus(error);
          setTimeout(() => setStatus(null), 10000);
        });
    }
  };

  useEffect(() => {
    loadUsers(activePage);
  }, [activePage]);

  const changePage = (index: number) => {
    setActivePage(index);
  };

  return (
    <>
      <div className="mt-4 mb-5">
        {status && <div className={"alert alert-danger"}>{status}</div>}

        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-weight-light text-secondary">Usuários</h5>
          {<Pagination page={page} onPageChange={changePage} />}
          <a href="/users/new" className="btn btn-warning">
            Adicionar
          </a>
        </div>
        <table className="table table-hover shadow rounded bg-white text-center p-0 m-0">
          <thead>
            <tr>
              <th scope="col">Usuário</th>
              <th scope="col">E-mail</th>
              <th scope="col">Permissão</th>
              <th scope="col">Empresa</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody className="table-borderless">
            {page.content?.map((item) => (
              <tr key={item.id}>
                <th scope="row">{item.name}</th>
                <td>{item.email}</td>
                <td>{returnUserPermission(item.role)}</td>
                <td>{item.company.name}</td>
                <td>
                  <a className="text-secondary" href={`/users/edit/${item.id}`}>
                    <PencilFill />
                  </a>{" "}
                  <a
                    className="text-secondary"
                    href="#"
                    onClick={() => deleteUser(item.name, item.email, item.id)}
                  >
                    <TrashFill />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataTable;
