import Chart from 'react-apexcharts';
import { BASE_URL } from 'utils/requests';
import { useEffect, useState } from 'react';
import { TotalSolicitation } from 'types/dataResponse';
import { authHeader, handleResponse } from 'helpers';

type ChartData = {
    labels: string[];
    series: number[];
    colors: string[];
}

const DonutChart = () => {
    const [chartData, setChartData] = useState<ChartData>({ labels: [], series: [], colors: [] });

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`${BASE_URL}/solicitation/totalbyuser`, requestOptions)
            .then(handleResponse)
            .then(response => {
                const data = response.data as TotalSolicitation;
                const myLabels = ["Pagos", "Pendentes", "Aprovados", "Rejeitados"];
                const mySeries = [data.paid, data.pending, data.approved, data.reject];
                const myColors = ["#0275d8", "#f0ad4e", "#5cb85c", "#d9534f"];

                setChartData({ labels: myLabels, series: mySeries, colors: myColors });
            });
    }, []);

    const options = {
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true
                    }
                }
            }
        }
    }

    return (
        <Chart
            options={{ ...options, labels: chartData.labels, colors: chartData.colors }}
            series={chartData.series}
            type="donut"
            height="200"
            labels={"false"}
        />
    );
}

export default DonutChart;
