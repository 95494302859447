import NavBar from "components/NavBar";
import { Role } from "helpers";
import React from "react";
import Routes from "Routes";
import { authenticationService } from "services";

class App extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false,
      isManager: false
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({
      currentUser: x,
      isAdmin: x && x.role === Role.Admin,
      isManager: x && x.role === Role.Manager
    }));
  }

  render() {
    return (
      <>
        <NavBar />
        <Routes />
      </>

    );
  }
}

export default App;