import { useRef, useEffect, InputHTMLAttributes } from "react";
import { useField } from "@unform/core";

interface Props {
  name: string;
  type?:
    | "text"
    | "number"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "hidden"
    | "month"
    | "password"
    | "time"
    | "range"
    | "search"
    | "tel"
    | "url"
    | "week";
  label?: string;
  value?: string;
  divClass?: string;
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & Props;

function Input({ name, type, label, value, divClass, ...rest }: InputProps) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  const defaultInputValue = value || defaultValue;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, newValue) => {
        ref.current.value = newValue;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={divClass || "form-group"}>
      <label
        htmlFor={fieldName}
        className={
          "font-weight-light" + (error ? " text-danger" : " text-secondary")
        }
      >
        {label}
      </label>

      <input
        type={type || "text"}
        id={fieldName}
        ref={inputRef}
        onFocus={clearError}
        defaultValue={defaultInputValue}
        className={
          "form-control font-weight-light" + (error ? " is-invalid" : "")
        }
        {...rest}
      />

      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
}

export default Input;
