/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
  import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { BASE_URL } from "utils/requests";
import Input from "components/Form/Input";
import DecimalInput from 'components/Form/DecimalInput';
import Select from "components/Form/Select";
import { authHeader, handleResponse } from "helpers";
import { CostCenter } from "types/dataResponse";

interface FormData {
  desc: string;
  costCenters: string[];
  value: number;
  codeExpense: string;
  expenseType: string;
  special: boolean;
  specialType: number;
  specialValue: number;
}

const CreateCategory = (props: any) => {
  const [status, setStatus] = useState(null);
  const formRef = useRef<FormHandles>(null);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [optionalFields, setOptionalFields] = useState(false);

  const typeOptions = [
    { value: 1, label: "Custo" },
    { value: 2, label: "Despesa" },
  ];

  const specialOptions = [
    { value: false, label: "Não" },
    { value: true, label: "Sim" },
  ];

  const specialTypeOptions = [{ value: "KM", label: "Reembolso de KM" }];

  const loadCostCenter = (e) => {
    const costCenterTypeNum = e?.value;

    const select = formRef!.current!.getFieldRef("costCenters");
    select.select.clearValue();

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${BASE_URL}/costcenter/bytype/` + costCenterTypeNum, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCostCenters(response.data);
      });
  };

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    console.log(data);
    try {
      const schema = Yup.object().shape({
        type: Yup.string().required("Escolha o tipo de despesa"),
        desc: Yup.string()
          .min(3, "A descrição digitada é muito curta")
          .required("A descrição é obrigatória"),
        value: Yup.string().required("O valor limite é obrigatório"),
        codeExpense: Yup.string().required("O código da despesa é obrigatório"),
        costCenters: Yup.array().min(
          1,
          "Escolha pelo menos um centro de custo"
        ),
        expenseType: Yup.string().required("O tipo da despesa é obrigatório"),
        special: Yup.boolean().required("Escolha sim ou não"),
        specialType: Yup.string().when("special", {
          is: true,
          then: Yup.string().required("Escolha o tipo especial da categoria"),
        }),
        specialValue: Yup.string().when("special", {
          is: true,
          then: Yup.string().required("Digite o valor do tipo especial"),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current?.setErrors({});

      let requestOptions;
      let finalUrl = `/category`;

      if (!!props.match.params.id) {
        requestOptions = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify({
            id: Number.parseInt(props.match.params.id),
            description: data.desc,
            limitValue: data.value,
            codeExpense: data.codeExpense,
            expenseType: data.expenseType,
            costCenters: data.costCenters.map((c) => ({ id: c })),
            special: data.special,
            specialType: data.special ? data.specialType : null,
            specialValue: data.special ? data.specialValue : null,
          }),
        };
        finalUrl = `/category/${props.match.params.id}`;
      } else {
        requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            description: data.desc,
            limitValue: data.value,
            codeExpense: data.codeExpense,
            expenseType: data.expenseType,
            costCenters: data.costCenters.map((c) => ({ id: c })),
            special: data.special,
            specialType: data.special ? data.specialType : null,
            specialValue: data.special ? data.specialValue : null,
          }),
        };
      }

      fetch(`${BASE_URL}${finalUrl}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          formRef.current?.reset();
          props.history.push("/configuration");
        })
        .catch((error) => {
          setStatus(error);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages: { [key in string]: string };
        errorMessages = {};
        err.inner.forEach((error: Yup.ValidationError) => {
          errorMessages[error.path!] = error.message;
        });
        formRef.current?.setErrors(errorMessages);
      }
    }
  };

  const loadItens = async () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    if (!!props.match.params.id) {
      await fetch(
        `${BASE_URL}/category/${props.match.params.id}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => response.data)
        .then(async (data) => {
          console.log(data);
          formRef.current?.setData({
            type: typeOptions.find(
              (t) =>
                t.label.toLowerCase() === data.costCenters[0].type.toLowerCase()
            ),
            desc: data.description,
            costCenters: data.costCenters?.map((c) => ({
              value: c.id,
              label: c.costCenter,
            })),
            value: data.limitValue,
            codeExpense: data.codeExpense,
            expenseType: data.expenseType,
            special: specialOptions.find((t) => t.value === data.special),
            specialType: specialTypeOptions.find(
              (t) => t.value === data.specialType
            ),
            specialValue: data.specialValue,
          });
        });
    }
  };

  useEffect(() => {
    loadItens();
  }, []);

  return (
    <div className="container">
      {status && <div className={"alert alert-danger"}>{status}</div>}
      <h3 className="display-5 font-weight-light text-secondary">
        Cadastrar Categoria
      </h3>
      <p className="lead font-weight-light text-secondary">
        Preencha os dados abaixo:
      </p>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="jumbotron shadow rounded bg-white mt-5 pt-4">
          <h5 className="pb-3">Dados</h5>
          <Select
            label="Tipo do centro de custo"
            name="type"
            onChange={(e) => {
              loadCostCenter(e);
            }}
            placeholder="Escolha o tipo do centro de custo para ser utilizado como filtro do campo abaixo"
            options={typeOptions}
          />

          <Select
            label="Centro de custo"
            name="costCenters"
            isMulti
            placeholder="Escolha pelo menos um centro de custo"
            options={costCenters?.map((costCenter) => ({
              value: costCenter.id,
              label: costCenter.costCenter,
            }))}
          />
          <Input
            label="Código de Despesa"
            name="codeExpense"
            type="text"
            placeholder="Insira o código de despesa"
          />
          <Input
            label="Nome da despesa"
            name="desc"
            type="text"
            placeholder="Insira a descrição da categoria"
          />
          <Input
            label="Tipo de Despesa"
            name="expenseType"
            type="text"
            placeholder="Insira código do tipo de despesa"
          />
          <Input
            label="Valor limite para a categoria"
            name="value"
            type="number"
            placeholder="Digite um valor monetário para o limite da categoria"
          />
          <Select
            label="É um tipo de categoria especial?"
            name="special"
            placeholder="Escolha uma opção"
            options={specialOptions}
            defaultValue={specialOptions[0]}
            onChange={(e) => {
              setOptionalFields(!e?.value);
            }}
          />
          <Select
            isDisabled={optionalFields}
            label="Caso a categoria seja diferenciada, de qual tipo é?"
            name="specialType"
            placeholder="Escolha uma opção caso a opção acima seja verdadeira"
            options={specialTypeOptions}
          />
          <DecimalInput
            disabled={optionalFields}
            label="Valor de cálculo de categoria especial"
            name="specialValue"
            type="number"
            placeholder="Digite um valor monetário para o cálculo caso a categoria seja de um tipo especial"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-5">
          <button type="submit" className="btn btn-warning mx-4">
            Salvar
          </button>
          <a
            href="/configuration"
            className="btn btn-outline-warning mx-4 text-reset"
          >
            Cancelar
          </a>
        </div>
      </Form>
    </div>
  );
};

export default CreateCategory;
