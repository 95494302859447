import { authHeader, handleResponse } from "helpers";
import { useEffect, useState } from "react";
import {
  Company,
  Category,
  CostCenter,
  Department,
  Occupation,
} from "types/dataResponse";
import { BASE_URL } from "utils/requests";

const ConfigurationDashboard = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [occupations, setOccupations] = useState<Occupation[]>([]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${BASE_URL}/company`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCompanies(response.data);
      });

    fetch(`${BASE_URL}/category`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCategories(response.data);
      });

    fetch(`${BASE_URL}/costcenter`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCostCenters(response.data);
      });

    fetch(`${BASE_URL}/department`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setDepartments(response.data);
      });

    fetch(`${BASE_URL}/occupation`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setOccupations(response.data);
      });
  }, []);

  return (
    <div className="container">
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col mb-4">
          <div className="card shadow rounded font-weight-light">
            <div className="card-header bg-white text-secondary text-center font-weight-light d-flex justify-content-between">
              <div className="m-auto">Centro de Custo</div>
              <div>
                <a
                  href="/costcenters/new"
                  className="btn btn-outline-secondary m-0 py-0 px-2"
                  role="button"
                  aria-disabled="true"
                >
                  +
                </a>
              </div>
            </div>
            <div className="card-body bg-white">
              <div className="list-group list-group-flush custom-scrollbar-css font-weight-normal">
                {costCenters?.map((costCenter) => (
                  <a
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    key={costCenter.id}
                    href={`/costcenters/edit/${costCenter.id}`}
                  >
                    <span className="font-weight-bold">
                      {costCenter.costCenter}
                    </span>
                    <span>{costCenter.description}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card shadow rounded">
            <div className="card-header bg-white text-secondary text-center font-weight-light d-flex justify-content-between">
              <div className="m-auto">Categorias</div>
              <div>
                <a
                  href="/categories/new"
                  className="btn btn-outline-secondary m-0 py-0 px-2"
                  role="button"
                  aria-disabled="true"
                >
                  +
                </a>
              </div>
            </div>
            <div className="card-body bg-white">
              <div className="list-group list-group-flush custom-scrollbar-css font-weight-normal">
                {categories?.map((category) => (
                  <a
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    key={category.id}
                    href={`/categories/edit/${category.id}`}
                  >
                    <span className="font-weight-bold">
                      {category.codeExpense}
                    </span>
                    <span>{category.description}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card shadow rounded font-weight-light">
            <div className="card-header bg-white text-secondary text-center font-weight-light d-flex justify-content-between">
              <div className="m-auto">Empresas</div>
              <div>
                <a
                  href="/companies/new"
                  className="btn btn-outline-secondary m-0 py-0 px-2"
                  role="button"
                  aria-disabled="true"
                >
                  +
                </a>
              </div>
            </div>
            <div className="card-body bg-white">
              <div className="list-group list-group-flush custom-scrollbar-css font-weight-normal">
                {companies?.map((company) => (
                  <a
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    key={company.id}
                    href={`/companies/edit/${company.id}`}
                  >
                    <span className="font-weight-bold">{company.name}</span>
                    <span>{company.description}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card shadow rounded font-weight-light">
            <div className="card-header bg-white text-secondary text-center font-weight-light d-flex justify-content-between">
              <div className="m-auto">Setor</div>
              <div>
                <a
                  href="/departments/new"
                  className="btn btn-outline-secondary m-0 py-0 px-2"
                  role="button"
                  aria-disabled="true"
                >
                  +
                </a>
              </div>
            </div>
            <div className="card-body bg-white">
              <div className="list-group list-group-flush custom-scrollbar-css font-weight-normal">
                {departments?.map((department) => (
                  <a
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    key={department.id}
                    href={`/departments/edit/${department.id}`}
                  >
                    <span className="font-weight-bold">{department.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card shadow rounded font-weight-light">
            <div className="card-header bg-white text-secondary text-center font-weight-light d-flex justify-content-between">
              <div className="m-auto">Cargo</div>
              <div>
                <a
                  href="/occupations/new"
                  className="btn btn-outline-secondary m-0 py-0 px-2"
                  role="button"
                  aria-disabled="true"
                >
                  +
                </a>
              </div>
            </div>
            <div className="card-body bg-white">
              <div className="list-group list-group-flush custom-scrollbar-css font-weight-normal">
                {occupations?.map((occupation) => (
                  <a
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    key={occupation.id}
                    href={`/occupations/edit/${occupation.id}`}
                  >
                    <span className="font-weight-bold">{occupation.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card shadow rounded font-weight-light">
            <div
              className="card-header bg-white text-secondary text-center font-weight-light d-flex justify-content-between "
              style={{ height: "20.7rem" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationDashboard;
