import React, { useRef, useEffect } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { useField } from '@unform/core';

interface Props extends InputProps {
    name: string
    label?: string
    divClass?: string
    value?: string
}

function InputMask({ name, label, divClass, value, ...rest }: Props) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',

            setValue(ref: any, value: string) {
                ref.setInputValue(value);
            },

            clearValue(ref: any) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    return (
        <div className={divClass || "form-group"}>
            <label htmlFor={fieldName} className={"font-weight-light" + (error ? " text-danger" : " text-secondary")}>{label}</label>

            <ReactInputMask
                type="text"
                id={fieldName}
                ref={inputRef}
                onFocus={clearError}
                defaultValue={defaultValue}
                className={"form-control font-weight-light" + (error ? " is-invalid" : "")}
                {...rest}
            />
            {error && <span className="invalid-feedback">{error}</span>}
        </div>
    );
};

export default InputMask;