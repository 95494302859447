/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { BASE_URL } from "utils/requests";
import Input from "components/Form/Input";
import { authHeader, handleResponse } from "helpers";

interface FormData {
  abbr: string;
  name: string;
  description: string;
}

const CreateCompany = (props: any) => {
  const [status, setStatus] = useState(null);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const schema = Yup.object().shape({
        abbr: Yup.string().required(
          "O código da empresa no SuperSoft é obrigatório"
        ),
        name: Yup.string().required("O nome da empresa é obrigatório"),
        description: Yup.string().required(
          "Descrição da empresa é obrigatória"
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current?.setErrors({});

      let requestOptions;
      let finalUrl = `/company`;

      if (!!props.match.params.id) {
        requestOptions = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify({
            name: data.name,
            nameAbbr: data.abbr,
            description: data.description,
          }),
        };
        finalUrl = `/company/${props.match.params.id}`;
      } else {
        requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            name: data.name,
            nameAbbr: data.abbr,
            description: data.description,
          }),
        };
      }

      fetch(`${BASE_URL}${finalUrl}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          formRef.current?.reset();
          props.history.push("/configuration");
        })
        .catch((error) => {
          setStatus(error);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages: { [key in string]: string };
        errorMessages = {};
        err.inner.forEach((error: Yup.ValidationError) => {
          errorMessages[error.path!] = error.message;
        });
        formRef.current?.setErrors(errorMessages);
      }
    }
  };

  const loadItens = async () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    if (!!props.match.params.id) {
      await fetch(
        `${BASE_URL}/company/${props.match.params.id}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => response.data)
        .then((data) => {
          formRef.current?.setData({
            abbr: data.nameAbbr,
            name: data.name,
            description: data.description,
          });
        });
    }
  };

  useEffect(() => {
    loadItens();
  }, []);

  return (
    <div className="container">
      {status && <div className={"alert alert-danger"}>{status}</div>}
      <h3 className="display-5 font-weight-light text-secondary">
        Cadastrar Empresa
      </h3>
      <p className="lead font-weight-light text-secondary">
        Preencha os dados abaixo:
      </p>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="jumbotron shadow rounded bg-white mt-5 pt-4">
          <h5 className="pb-3">Dados</h5>
          <Input
            label="Nome da Empresa"
            name="name"
            type="text"
            placeholder="Insira o nome da empresa"
          />
          <Input
            label="Descrição da Empresa"
            name="description"
            type="text"
            placeholder="Insira uma breve descrição sobre a empresa"
          />
          <Input
            label="Abreviação da Empresa"
            name="abbr"
            type="text"
            placeholder="Insira a abreviação da empresa cadastrada no SuperSoft"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-5">
          <button type="submit" className="btn btn-warning mx-4">
            Salvar
          </button>
          <a
            href="/configuration"
            className="btn btn-outline-warning mx-4 text-reset"
          >
            Cancelar
          </a>
        </div>
      </Form>
    </div>
  );
};

export default CreateCompany;
