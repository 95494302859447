/* eslint-disable react-hooks/exhaustive-deps */
import { authHeader, handleResponse } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { Company, Department, User, Occupation } from "types/dataResponse";
import { BASE_URL } from "utils/requests";
import { cpf } from "cpf-cnpj-validator";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import InputMask from "components/Form/InputMask";

interface FormData {
  id?: number;
  name: string;
  email: string;
  role: string;
  occupation: string;
  department: string;
  company: string;
  manager: string;
  cpf: string;
}

const CreateEditUser = (props: any) => {
  const [status, setStatus] = useState(null);
  const formRef = useRef<FormHandles>(null);

  const [companies, setCompanies] = useState<Company[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [managers, setManagers] = useState<User[]>([]);
  const [occupations, setOccupations] = useState<Occupation[]>([]);

  const roleOptions = [
    { value: "admin", label: "Administrador" },
    { value: "manager", label: "Gestor" },
    { value: "user", label: "Usuário" },
  ];

  const loadItens = async () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    await fetch(`${BASE_URL}/company`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCompanies(response.data);
      });
    await fetch(`${BASE_URL}/department`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setDepartments(response.data);
      });
    await fetch(`${BASE_URL}/users/managers`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setManagers(response.data);
      });
    await fetch(`${BASE_URL}/occupation`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setOccupations(response.data);
      });

    if (!!props.match.params.id) {
      await fetch(`${BASE_URL}/users/${props.match.params.id}`, requestOptions)
        .then(handleResponse)
        .then((response) => response.data)
        .then((data) => {
          formRef.current?.setData({
            id: data.id,
            name: data.name,
            email: data.email,
            company: { value: data.company?.id, label: data.company?.name },
            role: {
              value: data.role,
              label: roleOptions.find((r) => r.value === data.role)?.label,
            },
            department: {
              value: data.department?.id,
              label: data.department?.name,
            },
            manager: { value: data.manager?.id, label: data.manager?.name },
            occupation: {
              value: data.occupation?.id,
              label: data.occupation?.name,
            },
            cpf: data.cpf,
          });
        });
    }
  };

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("O nome é necessário"),
        email: Yup.string().required("O e-mail é necessário"),
        company: Yup.string().required("Selecione a empresa"),
        role: Yup.string().required("Selecione a permissão"),
        department: Yup.string().required("Selecione o departamento"),
        occupation: Yup.string().required("Selecione o cargo"),
        cpf: Yup.string().test("cpf", "CPF Inválido", (value: any) => {
          const cpfTyped = value || "";
          return cpf.isValid(cpfTyped.replace(/-|_|\./g, ""));
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current?.setErrors({});

      let requestOptions;
      let userUrl = `/users`;

      if (!!props.match.params.id) {
        requestOptions = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify({
            id: Number.parseInt(props.match.params.id),
            name: data.name,
            email: data.email,
            role: data.role,
            department: { id: Number.parseInt(data.department) },
            company: { id: Number.parseInt(data.company) },
            occupation: { id: Number.parseInt(data.occupation) },
            manager: { id: Number.parseInt(data.manager) },
            cpf: data.cpf.replace(/-|_|\./g, ""),
          }),
        };
        userUrl = `/users/${props.match.params.id}`;
      } else {
        requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            name: data.name,
            email: data.email,
            role: data.role,
            department: { id: Number.parseInt(data.department) },
            company: { id: Number.parseInt(data.company) },
            occupation: { id: Number.parseInt(data.occupation) },
            manager: { id: Number.parseInt(data.manager) },
            cpf: data.cpf.replace(/-|_|\./g, ""),
          }),
        };
      }

      fetch(`${BASE_URL}${userUrl}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          props.history.push("/users");
          formRef.current?.reset();
        })
        .catch((error) => {
          setStatus(error);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages: { [key in string]: string };
        errorMessages = {};
        err.inner.forEach((error: Yup.ValidationError) => {
          errorMessages[error.path!] = error.message;
        });
        formRef.current?.setErrors(errorMessages);
      }
    }
  };

  useEffect(() => {
    loadItens();
  }, []);

  return (
    <div className="container">
      {status && <div className={"alert alert-danger"}>{status}</div>}
      <h3 className="display-5 font-weight-light text-secondary">
        Cadastrar Usuário
      </h3>
      <p className="lead font-weight-light text-secondary">
        Preencha os dados abaixo:
      </p>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="jumbotron shadow rounded bg-white mt-5 p-5">
          <h5 className="pb-3">Dados Pessoais</h5>

          <Input
            label="Nome completo"
            name="name"
            type="text"
            placeholder="Insira o nome completo"
          />

          <Input
            label="E-mail"
            name="email"
            type="email"
            placeholder="Insira o e-mail"
          />

          <InputMask
            label="CPF do usuário"
            name="cpf"
            mask="999.999.999-99"
            placeholder="Digite o CPF do Usuário"
          />

          <div className="form-group row">
            <Select
              label="Empresa"
              name="company"
              placeholder="Escolha uma empresa"
              divClass="col"
              options={companies?.map((company) => ({
                value: company.id,
                label: company.name,
              }))}
            />

            <Select
              label="Permissão"
              name="role"
              placeholder="Escolha o perfil de usuário"
              divClass="col"
              options={roleOptions}
            />
          </div>

          <div className="form-group row">
            <Select
              label="Setor"
              name="department"
              placeholder="Escolha um setor"
              divClass="col"
              options={departments?.map((department) => ({
                value: department.id,
                label: department.name,
              }))}
            />

            <Select
              label="Gestor"
              name="manager"
              placeholder="Escolha um gestor"
              divClass="col"
              options={managers?.map((manager) => ({
                value: manager.id,
                label: manager.name,
              }))}
            />
          </div>

          <Select
            label="Cargo"
            name="occupation"
            placeholder="Escolha um cargo"
            options={occupations?.map((occupation) => ({
              value: occupation.id,
              label: occupation.name,
            }))}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-5">
          <button type="submit" className="btn btn-warning mx-4">
            Salvar
          </button>
          <a href="/users" className="btn btn-outline-warning mx-4 text-reset">
            Cancelar
          </a>
        </div>
      </Form>
    </div>
  );
};

export default CreateEditUser;
