import ImgUnion from 'assets/img/logo-union.png';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { PersonFill } from 'react-bootstrap-icons';
import { authenticationService } from "services";

const NavBar = (props: any) => {
    const returnMenuItensForValidUser = () => {
        if (!!authenticationService.currentUserValue) {
            return (
                <>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="/" className="font-weight-light navlinks">Reembolsos</Nav.Link>
                            {authenticationService.currentUserValue.role === "admin" && <Nav.Link href="/users" className="font-weight-light navlinks">Usuários</Nav.Link>}
                            {["manager", "admin"].indexOf(authenticationService.currentUserValue.role) !== -1 && <Nav.Link href="/solicitations" className="font-weight-light navlinks">Solicitações</Nav.Link>}
                            <Nav.Link href="/expenses/new" className="font-weight-light navlinks">Lançar Despesa</Nav.Link>
                            {authenticationService.currentUserValue.role === "admin" && <Nav.Link href="/configuration" className="font-weight-light navlinks">Configuração</Nav.Link>}
                            <NavDropdown title={<PersonFill />} id="navbarScrollingDropdown" className="font-weight-light navlinks" alignRight={true}>
                                <NavDropdown.Item href="/users/me" className="font-weight-light">Perfil</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#" onClick={authenticationService.logout} className="font-weight-light">Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </>
            )
        }
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="d-flex flex-column flex-md-row align-items-center p-4 px-md-5 mb-5 border-bottom shadow-sm">
            <Navbar.Brand href="/">
                <img src={ImgUnion} alt="Union IT Digital" width="180" />
            </Navbar.Brand>
            {returnMenuItensForValidUser()}
        </Navbar >
    );
}

export default NavBar;
