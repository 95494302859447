/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { DataPage } from "types/dataResponse";
import { BASE_URL } from "utils/requests";
import Pagination from "../Pagination"
import { Download, FileEarmarkTextFill } from 'react-bootstrap-icons';
import { authHeader, handleResponse } from "helpers";
import { Button, Modal } from "react-bootstrap";

const returnStatus = (status: string) => {
    if (status === "PAGO") {
        return <p className="text-primary user-select-none p-0 m-0">Pago</p>
    } else if (status === "APROVADO") {
        return <p className="text-success p-0 m-0">Aprovado</p>
    } else if (status === "REPROVADO") {
        return <p className="text-danger p-0 m-0">Reprovado</p>
    } else if (status === "PENDENTE_FINANCEIRO") {
        return <p className="text-warning p-0 m-0">Aguardando financeiro</p>
    } else if (status === "PENDENTE_DIRETORIA") {
        return <p className="text-warning p-0 m-0">Aguardando diretoria</p>
    } else if (status === "PENDENTE_GESTOR") {
        return <p className="text-warning p-0 m-0">Aguardando gestor</p>
    } else {
        return <p className="text-warning p-0 m-0">Status desconhecido</p>
    }
}

const DataTable = () => {
    const [activePage, setActivePage] = useState(0);
    const [solicitationDescription, setSolicitationDescription] = useState(null);
    const [solicitationFeedback, setSolicitationFeedback] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const handleCloseDetailsModal = () => setShowDetailsModal(false);
    const handleShowDetailsModal = () => setShowDetailsModal(true);

    const [page, setPage] = useState<DataPage>({
        first: true,
        last: true,
        number: 0,
        totalElements: 0,
        totalPages: 0
    });

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`${BASE_URL}/solicitation/byuser?page=${activePage}&size=20&sort=date,desc`, requestOptions)
            .then(handleResponse)
            .then(response => {
                setPage(response.data);
            });
    }, [activePage]);

    const changePage = (index: number) => {
        setActivePage(index);
    }

    return (
        <>
            <div className="mt-5 mb-5">
                <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalhes da Solicitação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Descrição da Solicitação</h5>
                        <p>{solicitationDescription}</p>
                    </Modal.Body>
                    <Modal.Body>
                        <h5>Feedback do gestor</h5>
                        <p>{solicitationFeedback || "Ainda sem feedback"}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={handleCloseDetailsModal}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="font-weight-light text-secondary">Solicitações realizadas</h5>
                    <Pagination page={page} onPageChange={changePage} />
                </div>
                <table className="table table-hover shadow rounded bg-white text-center p-0 m-0">
                    <thead>
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Centro de Custo</th>
                            <th scope="col">Categoria</th>
                            <th scope="col">Valor</th>
                            <th scope="col">Detalhes</th>
                            <th scope="col">Anexo</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody className="table-borderless">
                        {page.content?.map(item => (
                            <tr key={item.id}>
                                <th scope="row">{new Date(item.date).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</th>
                                <td>{item.costCenter.description}</td>
                                <td>{item.category.description}</td>
                                <td>R$ {item.value.toFixed(2).toString().replace('.', ',')}</td>
                                <td>
                                    <a className="text-sucess font-weight-bold" href="#" onClick={() => {
                                        setSolicitationDescription(item.description);
                                        setSolicitationFeedback(item.feedback);
                                        handleShowDetailsModal();
                                    }}><FileEarmarkTextFill className="text-secondary font-weight-bold" /></a>
                                </td>
                                <td><a href={`${BASE_URL}/solicitation/files/${item.attachment}`}><Download className="text-secondary font-weight-bold" /></a></td>
                                <td>{returnStatus(item.status)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default DataTable;
