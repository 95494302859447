import React, { useRef, useEffect } from "react";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import { useField } from "@unform/core";

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  divClass?: string;
  isMulti?: any;
  label: string;
}

function Select({ name, divClass, label, ...rest }: Props) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: (ref) => {
        ref.select.clearValue();
      },
      getValue: rest.isMulti
        ? (ref) =>
            ref.state.value?.map((option: OptionTypeBase) => option.value) || []
        : (ref) => (ref.state.value ? ref.state.value.value : ""),
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div className={divClass || "form-group"}>
      <label
        htmlFor={fieldName}
        className={
          "font-weight-light" + (error ? " text-danger" : " text-secondary")
        }
      >
        {label}
      </label>

      <ReactSelect
        cacheOptions
        className={"font-weight-light" + (error ? " is-invalid" : "")}
        id={fieldName}
        ref={selectRef}
        onFocus={clearError}
        defaultValue={defaultValue}
        noOptionsMessage={() => "Nenhum resultado encontrado"}
        {...rest}
      />

      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
}

export default Select;
