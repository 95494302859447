import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import User from "./pages/User";
import LoginUser from "./pages/LoginUser";
import CreateEditUser from "./pages/CreateEditUser";
import CreateExpense from "./pages/CreateExpense";
import CreateCategory from "./pages/CreateCategory";
import CreateCostCenter from "./pages/CreateCostCenter";
import CreateCompany from "./pages/CreateCompany";
import CreateProject from "./pages/CreateProject";
import ConfigurationDashboard from "./pages/ConfigurationDashboard";
import PrivateRoute from "./components/PrivateRoute";
import SolicitationDashboard from "./pages/SolicitationDashboard";
import EditCurrentUser from "./pages/EditCurrentUser";
import ResetPasswordUser from "./pages/ResetPasswordUser";
import CreateDepartment from "pages/CreateDepartment";
import CreateOccupation from "pages/CreateOccupation";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={LoginUser} path="/users/login" exact />
        <Route
          component={ResetPasswordUser}
          path="/users/resetpassword"
          exact
        />

        <PrivateRoute component={Dashboard} path="/" exact />
        <PrivateRoute component={EditCurrentUser} path="/users/me" exact />

        <PrivateRoute
          component={ConfigurationDashboard}
          path="/configuration"
          roles={["admin"]}
          exact
        />

        <PrivateRoute component={User} path="/users" roles={["admin"]} exact />
        <PrivateRoute
          component={CreateEditUser}
          path="/users/new"
          roles={["admin"]}
          exact
        />
        <PrivateRoute
          component={CreateEditUser}
          path="/users/edit/:id"
          roles={["admin"]}
          exact
        />

        <PrivateRoute component={CreateExpense} path="/expenses/new" exact />

        <PrivateRoute
          component={CreateCategory}
          path="/categories/new"
          roles={["admin"]}
          exact
        />
        <PrivateRoute
          component={CreateCategory}
          path="/categories/edit/:id"
          roles={["admin"]}
          exact
        />

        <PrivateRoute
          component={CreateCostCenter}
          path="/costcenters/new"
          roles={["admin"]}
          exact
        />
        <PrivateRoute
          component={CreateCostCenter}
          path="/costcenters/edit/:id"
          roles={["admin"]}
          exact
        />

        <PrivateRoute
          component={CreateCompany}
          path="/companies/new"
          roles={["admin"]}
          exact
        />
        <PrivateRoute
          component={CreateCompany}
          path="/companies/edit/:id"
          roles={["admin"]}
          exact
        />

        <PrivateRoute
          component={CreateProject}
          path="/projects/new"
          roles={["admin"]}
          exact
        />
        <PrivateRoute
          component={CreateProject}
          path="/projects/edit/:id"
          roles={["admin"]}
          exact
        />

        <PrivateRoute
          component={CreateDepartment}
          path="/departments/new"
          roles={["admin"]}
          exact
        />
        <PrivateRoute
          component={CreateDepartment}
          path="/departments/edit/:id"
          roles={["admin"]}
          exact
        />

        <PrivateRoute
          component={CreateOccupation}
          path="/occupations/new"
          roles={["admin"]}
          exact
        />
        <PrivateRoute
          component={CreateOccupation}
          path="/occupations/edit/:id"
          roles={["admin"]}
          exact
        />

        <PrivateRoute
          component={SolicitationDashboard}
          path="/solicitations"
          roles={["admin", "manager"]}
          exact
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
