import Chart from "react-apexcharts";

const SolicitationDonutChart = ({ chartData, ...rest }: any) => {
  const options = {
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
  };

  return (
    <Chart
      options={{
        ...options,
        labels: chartData.labels,
        colors: chartData.colors,
      }}
      series={chartData.series}
      type="donut"
      height="175"
      labels={"false"}
    />
  );
};

export default SolicitationDonutChart;
