import React, { useRef, useState } from "react";
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup';
import { BASE_URL } from "utils/requests";
import Input from "components/Form/Input";
import { authHeader, handleResponse } from "helpers";

interface FormData {
    email: string
}

const ResetPasswordUser = (props: any) => {
    const [status, setStatus] = useState(null);
    const [statusCssClass, setStatusCssClass] = useState('alert alert-danger');
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = async data => {
        try {
            const schema = Yup.object().shape({
                email: Yup.string().email("Digite um e-mail válido").required("O email é obrigatório"),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            formRef.current?.setErrors({});

            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            }

            fetch(`${BASE_URL}/users/resetpassword?email=${data.email}`, requestOptions)
                .then(handleResponse)
                .then(response => {
                    setStatusCssClass('alert alert-primary');
                    setStatus(response.data);
                    formRef.current?.reset();
                    setTimeout(() => props.history.push("/"), 5000);
                })
                .catch(error => {
                    setStatusCssClass('alert alert-danger');
                    setStatus(error);
                });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                let errorMessages: { [key in string]: string };
                errorMessages = {};
                err.inner.forEach((error: Yup.ValidationError) => {
                    errorMessages[error.path!] = error.message;
                });
                formRef.current?.setErrors(errorMessages);
            }
        }
    }

    return (
        <div className="container">
            {status &&
                <div className={statusCssClass}>{status}</div>
            }
            <h3 className="display-5 font-weight-light text-secondary">Esqueci minha senha</h3>
            <p className="lead font-weight-light text-secondary">Digite o seu email abaixo:</p>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="jumbotron shadow rounded bg-white mt-5 pt-4">
                    <Input label="E-mail" name="email" type="text" placeholder="Digite o seu e-mail cadastrado no sistema" />
                </div>
                <div className="d-flex justify-content-center align-items-center mb-5">
                    <button type="submit" className="btn btn-warning mx-4">Trocar Senha</button>
                    <a href="/" className="btn btn-outline-warning mx-4 text-reset">Cancelar</a>
                </div>
            </Form>
        </div>
    );
}

export default ResetPasswordUser;
