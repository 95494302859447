import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

interface Props {
  name: string;
  label?: string;
  value?: string;
  divClass?: string;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

function FileInput({ name, label, value, divClass, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref) {
        let files: object[] = [];

        for (let i = 0; i < ref.files.length; i += 1) {
          files.push({
            name: ref.files[i].name,
            size: ref.files[i].size,
            file: ref.files[i],
          });
        }

        return files;
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={divClass || "form-group"}>
      <label
        htmlFor={fieldName}
        className={
          "font-weight-light" + (error ? " text-danger" : " text-secondary")
        }
      >
        {label}
      </label>
      <div>
        <input
          type="file"
          id={fieldName}
          ref={inputRef}
          className={
            "font-weight-light" + (error ? " text-danger" : " text-secondary")
          }
          {...rest}
        />
      </div>
      {error && <span className="text-danger font-weight-light">{error}</span>}
    </div>
  );
}

export default FileInput;
