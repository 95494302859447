import DataTable from "components/DataTable";
import DonutChart from "components/DonutChart";
import { authHeader, handleResponse } from "helpers";
import { useEffect, useState } from "react";
import { TotalValueSolicitation } from "types/dataResponse";
import { BASE_URL } from "utils/requests";

const Dashboard = () => {
    const [expenseValues, setExpenseValues] = useState<TotalValueSolicitation>({
        totalApproved: 0,
        totalPaid: 0,
        totalPending: 0,
        totalReject: 0
    });

    const sumTotalValue = (expenses: TotalValueSolicitation) => {
        return expenses.totalApproved + expenses.totalPaid + expenses.totalPending + expenses.totalReject;
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`${BASE_URL}/solicitation/totalvaluebyuser`, requestOptions)
            .then(handleResponse)
            .then(response => {
                setExpenseValues(response.data as TotalValueSolicitation);
            });
    }, []);

    return (
        <div className="container">
            <div className="card-columns">
                <div className="card text-center shadow rounded">
                    <div className="card-header bg-white text-secondary font-weight-light">Total de Despesas</div>
                    <h3 className="card-title font-weight-light text-secondary pt-3 pb-0">R$ {sumTotalValue(expenseValues).toFixed(2).toString().replace('.', ',')}</h3>
                    <div className="card-body bg-white m-0 p-0">
                        <DonutChart />
                    </div>
                </div>

                <div className="card text-center shadow rounded font-weight-light">
                    <div className="card-header bg-white text-secondary">Pagos</div>
                    <div className="card-body bg-white">
                        <h1 className="card-text font-weight-light text-primary">R$ {expenseValues.totalPaid.toFixed(2).toString().replace('.', ',')}</h1>
                    </div>
                </div>
                <div className="card text-center shadow rounded font-weight-light">
                    <div className="card-header bg-white text-secondary">Aprovados</div>
                    <div className="card-body bg-white">
                        <h1 className="card-text font-weight-light text-success">R$ {expenseValues.totalApproved.toFixed(2).toString().replace('.', ',')}</h1>
                    </div>
                </div>
                <div className="card text-center shadow rounded font-weight-light">
                    <div className="card-header bg-white text-secondary">Pendentes</div>
                    <div className="card-body bg-white">
                        <h1 className="card-text font-weight-light text-warning">R$ {expenseValues.totalPending.toFixed(2).toString().replace('.', ',')}</h1>
                    </div>
                </div>
                <div className="card text-center shadow rounded font-weight-light">
                    <div className="card-header bg-white text-secondary">Rejeitados</div>
                    <div className="card-body bg-white">
                        <h1 className="card-text font-weight-light text-danger">R$ {expenseValues.totalReject.toFixed(2).toString().replace('.', ',')}</h1>
                    </div>
                </div>
            </div>

            {<DataTable />}
        </div>
    );
}

export default Dashboard;
