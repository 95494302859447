/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { BASE_URL } from "utils/requests";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import { authHeader, handleResponse } from "helpers";
import { Company } from "types/dataResponse";

interface FormData {
  name: string;
  companies: string[];
}

const CreateDepartment = (props: any) => {
  const [status, setStatus] = useState(null);
  const formRef = useRef<FormHandles>(null);
  const [companies, setCompanies] = useState<Company[]>([]);

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, "O nome digitado é muito curto")
          .required("O nome é obrigatório"),
        companies: Yup.array().min(1, "Escolha pelo menos uma empresa"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current?.setErrors({});

      let requestOptions;
      let finalUrl = `/department`;

      if (!!props.match.params.id) {
        requestOptions = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify({
            id: Number.parseInt(props.match.params.id),
            name: data.name,
            companies: data.companies.map((c) => ({ id: c })),
          }),
        };
        finalUrl = `/department/${props.match.params.id}`;
      } else {
        requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            name: data.name,
            companies: data.companies.map((c) => ({ id: c })),
          }),
        };
      }

      fetch(`${BASE_URL}${finalUrl}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          formRef.current?.reset();
          props.history.push("/configuration");
        })
        .catch((error) => {
          setStatus(error);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages: { [key in string]: string };
        errorMessages = {};
        err.inner.forEach((error: Yup.ValidationError) => {
          errorMessages[error.path!] = error.message;
        });
        formRef.current?.setErrors(errorMessages);
      }
    }
  };

  const loadItens = async () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    await fetch(`${BASE_URL}/company`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCompanies(response.data);
      });

    if (!!props.match.params.id) {
      await fetch(
        `${BASE_URL}/department/${props.match.params.id}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => response.data)
        .then((data) => {
          formRef.current?.setData({
            name: data.name,
            companies: data.companies?.map((c) => ({
              value: c.id,
              label: c.name,
            })),
          });
        });
    }
  };

  useEffect(() => {
    loadItens();
  }, []);

  return (
    <div className="container">
      {status && <div className={"alert alert-danger"}>{status}</div>}
      <h3 className="display-5 font-weight-light text-secondary">
        Cadastrar Setor
      </h3>
      <p className="lead font-weight-light text-secondary">
        Preencha os dados abaixo:
      </p>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="jumbotron shadow rounded bg-white mt-5 pt-4">
          <h5 className="pb-3">Dados</h5>
          <Input
            label="Nome do setor"
            name="name"
            type="text"
            placeholder="Insira o nome do setor"
          />

          <Select
            label="Empresa"
            name="companies"
            isMulti
            placeholder="Escolha pelo menos uma empresa"
            options={companies?.map((company) => ({
              value: company.id,
              label: company.name,
            }))}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-5">
          <button type="submit" className="btn btn-warning mx-4">
            Salvar
          </button>
          <a
            href="/configuration"
            className="btn btn-outline-warning mx-4 text-reset"
          >
            Cancelar
          </a>
        </div>
      </Form>
    </div>
  );
};

export default CreateDepartment;
