import UserTable from "components/UserTable";

const Dashboard = () => {
    return (
        <div className="container">
            {<UserTable />}
        </div>
    );
}

export default Dashboard;
