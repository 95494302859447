import { BASE_URL } from 'utils/requests';
import { FetchWrapper } from '../helpers';
const baseUrl = `${BASE_URL}/users`;

export const userService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete
};

function getAll() {
    return FetchWrapper.get(baseUrl);
}

function getById(id: number) {
    return FetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params: any) {
    return FetchWrapper.post(baseUrl, params);
}

function update(id: number, params: any) {
    return FetchWrapper.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id: number) {
    return FetchWrapper.delete(`${baseUrl}/${id}`);
}