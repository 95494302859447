import Input from '../../components/Form/Input'
import React, { useRef, useState, useEffect } from "react";
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup';
import { authenticationService } from "services";

interface FormData {
    email: string
    password: string
}

const LoginUser = (props: any) => {
    const [status, setStatus] = useState<any>(null);

    const formRef = useRef<FormHandles>(null);

    useEffect(() => {
        if (authenticationService.currentUserValue) {
            window.location.href = '/';
        }
    });

    const handleSubmit: SubmitHandler<FormData> = async data => {
        try {
            const schema = Yup.object().shape({
                email: Yup.string().email("Informe um e-mail válido").required("E-mail obrigatório"),
                password: Yup.string().required("Senha obrigatório"),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            formRef.current?.setErrors({});

            try {
                await authenticationService.login(data.email, data.password);

                formRef.current?.reset();

                const { from } = props.location.state || { from: { pathname: "/" } };
                window.location.href = from.pathname;
            } catch (error) {
                setStatus(error);
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                let errorMessages: { [key in string]: string };
                errorMessages = {};
                err.inner.forEach((error: Yup.ValidationError) => {
                    errorMessages[error.path!] = error.message;
                });
                formRef.current?.setErrors(errorMessages);
            }
        }
    }

    return (
        <div className="container">
            {status &&
                <div className={'alert alert-danger'}>{status}</div>
            }
            <Form ref={formRef} onSubmit={handleSubmit}>
                <h3 className="display-5 font-weight-light text-secondary">Entrar</h3>
                <p className="lead font-weight-light text-secondary">Preencha os dados abaixo para fazer o login.</p>
                <div className="jumbotron shadow rounded bg-white mt-5 p-5">
                    <Input label="E-mail" name="email" type="email" placeholder="Insira seu e-mail" />
                    <Input label="Senha" name="password" type="password" placeholder="Insira sua senha" />

                    <p className="p-0 lead font-weight-light text-secondary">Esqueceu a senha? Clique <a href="/users/resetpassword">aqui</a>!</p>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-5">
                    <button type="submit" className="btn btn-warning mx-4">Entrar</button>
                </div>
            </Form>
        </div>
    );
}

export default LoginUser;
